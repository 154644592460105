<!-- 添加回答 -->
<template>
  <div class="main">
    <!-- 题目 -->
    <div class="answer_top">
      <div class="answer_top_left">Q</div>
      <div class="answer_top_bottom">
        <div class="name">{{info.work_name}}</div>
        <div class="details">{{info.work_content}}</div>
      </div>
    </div>
    <!-- 我的回答 -->
    <div class="my-answer">
      <p>我的回答</p>
      <van-uploader class="uploader"
                    v-model="fileList"
                    multiple
                    :max-count="9"
                    :after-read="afterRead"
                    :before-delete="deleteImg"
      />
    </div>
    <!-- 提交答案 -->
    <div class="click bton ct" @click="update">提交答案</div>
  </div>
</template>

<script>
  import {addWork, getWorkInfo, postWork} from "../../api/classRing";
  import qiniuUpload from "../../api/qiniuUpload";

  export default {
    name: "uploadAnswer",
    data() {
      return {
        workId: this.$route.query.workid, // 作业问题id
        wordRecordId: this.$route.query.wordrecordid, // 回答id
        info: {}, // 练习题信息
        fileList:[], // 文件列表
        imgUrlList:[], // 上传图片列表
      }
    },
    methods:{
      init() {
        getWorkInfo(this.workId).then(res => {
          this.info = res.data;
        })
      },
      afterRead(info) {
        console.log(info.file)
      },
      deleteImg(info,detail) {
        this.fileList.splice(detail.index,1);
      },
      // 提交答案
      update() {
        for (let i in this.fileList) {
          if (this.fileList[i].file) { // 有文件
            qiniuUpload(this.fileList[i].file,(url) => {
              this.imgUrlList.push(url);
              if (this.imgUrlList.length===this.fileList.length) {
                // 全部图片上传完毕
                if (this.wordRecordId!='undefined') { // 更新
                  postWork(this.wordRecordId,this.imgUrlList.join('|')).then(() => {
                    this.$toast('上传完成');
                    setTimeout(() => {
                      this.$router.go(-1);
                    },1000)
                  })
                } else { // 添加
                  addWork(this.workId,this.imgUrlList.join('|')).then(() => {
                    this.$toast('上传完成');
                    setTimeout(() => {
                      this.$router.go(-1);
                    },1000)
                  })
                }
              }
            })
          }
        }

      }
    },
    mounted() {this.init()}
  }
</script>

<style scoped>
  .main{
    margin: 0 4vw;
  }
  /*题目*/
  .answer_top {
    padding: 5vw 0;
    width: 92vw;
    margin: 0 auto;
    border-bottom: 0.233vw solid #edeff2;
    display: flex;
  }
  .answer_top_left {
    margin-top: 1.2vw;
    width: 6.333vw;
    height: 4vw;
    background-image: linear-gradient(-55deg, #f0b860 0%, #ffe7c1 100%);
    border-radius: 0 1.067vw 1.067vw 1.067vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.467vw;
    color: #ffffff;
  }
  .answer_top_bottom {
    margin-left: 3.467vw;
    width: 80vw;
  }
  .name {
    font-size: 4.267vw;
    color: #303133;
  }
  .details {
    font-size: 3.467vw;
    color: #909399;
    margin-top: 2.933vw;
  }
  /*我的回答*/
  .my-answer{
    margin-top: 5vw;
  }
  .uploader{
    margin-top: 3vw;
  }
  /*提交答案*/
  .click{
    width: 92vw;
    height: 12.533vw;
    background-color: #247dff;
    border-radius: 6.267vw;
    font-size: 4.8vw;
    color: #ffffff;
    position: fixed;
    bottom: 5vw;
  }
</style>
